// Remove old Vue imports
// import Vue from "vue";
// import VueRouter from "vue-router";

// Add new imports for Vue Router 4
import { createRouter, createWebHistory } from "vue-router";
import { getAuth, onAuthStateChanged } from "firebase/auth";

const routes = [
  {
    path: "/",
    component: () => import("@/views/HomeView.vue"),
    meta: {
      title: "Play Classic Puzzles & Games Online | larsmiloni.com",
      description:
        "larsmiloni.com offers a collection of classic puzzles and games including Sudoku, Sliding Puzzle, Hangman, Tic Tac Toe, and 2048. Dive in and challenge your skills for free.",
      favicon: "favicon.ico",
    },
  },
  {
    path: "/slidingPuzzle",
    component: () => import("@/views/SlidingPuzzleView.vue"),
    meta: {
      title: "Play Sliding Puzzle Online | larsmiloni.com",
      description:
        "Challenge your brain with our Sliding Puzzle game. Dive in and solve exciting puzzles for free on larsmiloni.com.",
      favicon: "slidingPuzzleFavicon.ico",
    },
  },
  {
    path: "/sudoku",
    component: () => import("@/views/SudokuView.vue"),
    meta: {
      title: "Play Sudoku Online | larsmiloni.com",
      description:
        "Test your logical thinking with online Sudoku puzzles. Enjoy a seamless gaming experience and sharpen your skills on larsmiloni.com.",
      favicon: "sudokuFavicon.ico",
    },
  },
  {
    path: "/hangman",
    component: () => import("@/views/HangmanView.vue"),
    meta: {
      title: "Play Hangman Online | larsmiloni.com",
      description:
        "Test your word-guessing abilities with online Hangman games. Challenge yourself and improve your vocabulary on larsmiloni.com.",
      favicon: "hangmanFavicon.ico",
    },
  },
  {
    path: "/ticTacToe",
    component: () => import("@/views/TicTacToeView.vue"),
    meta: {
      title: "Play Tic Tac Toe Online | larsmiloni.com",
      description:
        "Test your strategy skills with online TicTacToe games. Challenge yourself on larsmiloni.com.",
      favicon: "ticTacToeFavicon.ico",
    },
  },
  {
    path: "/2048",
    component: () => import("@/views/2048View.vue"),
    meta: {
      title: "Play 2048 Online | larsmiloni.com",
      description:
        "Slide and merge tiles to reach the 2048 tile. Test your logic and strategy skills with this addictive game on larsmiloni.com.",
      favicon: "2048Favicon.ico",
    },
  },
  /*
  {
    path: "/create-account",
    component: () => import("@/views/CreateAccountView.vue"),
  },
  {
    path: "/sign-in",
    component: () => import("@/views/SignInView.vue"),
    meta: {
      title: "Sign In to larsmiloni.com",
      description:
        "Access your favorite Sudoku and Sliding Puzzle games. Sign in to your account on larsmiloni.com and play on!",
    },
  },
  {
    path: "/profile",
    component: () => import("@/views/ProfileView.vue"),
    meta: {
      requiresAuth: true,
      title: "Your Profile | larsmiloni.com",
      description: "See you profile",
    },
  },
  */
];

// Update the way you create the router instance
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL || "/"),
  routes,
});

const getCurrentUser = () => {
  return new Promise((resolve, reject) => {
    const removeListener = onAuthStateChanged(
      getAuth(),
      (user) => {
        removeListener();
        resolve(user);
      },
      reject
    );
  });
};

router.beforeEach(async (to, from, next) => {
  document.title =
    to.meta.title || "Play Classic Puzzles & Games Online | larsmiloni.com";

  const metaDescriptionTag = document.querySelector('meta[name="description"]');
  if (metaDescriptionTag) {
    metaDescriptionTag.setAttribute(
      "content",
      to.meta.description || "Default Description"
    );
  } else {
    const newMetaDesc = document.createElement("meta");
    newMetaDesc.name = "description";
    newMetaDesc.content = to.meta.description || "Default Description";
    document.head.appendChild(newMetaDesc);
  }

  const link =
    document.querySelector("link[rel*='icon']") ||
    document.createElement("link");
  link.type = "image/x-icon";
  link.rel = "shortcut icon";
  link.href = to.meta.favicon
    ? `${process.env.BASE_URL}${to.meta.favicon}`
    : `${process.env.BASE_URL}defaultFavicon.ico`; // Fallback to a default favicon if not specified

  // Check if the link element already exists in the document
  if (!document.querySelector("link[rel*='icon']")) {
    document.getElementsByTagName("head")[0].appendChild(link);
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (await getCurrentUser()) {
      next();
    } else {
      alert("You dont have access!");
      next("/");
    }
  } else {
    next();
  }
});

export default router;
