import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { initializeApp } from "firebase/app";

const firebaseConfig = {
  apiKey: "AIzaSyCvTcTn6kVATOftenMtzw20h3heQspUKoM",
  authDomain: "game-webapp-82c3e.firebaseapp.com",
  databaseURL: "https://game-webapp-82c3e-default-rtdb.firebaseio.com",
  projectId: "game-webapp-82c3e",
  storageBucket: "game-webapp-82c3e.appspot.com",
  appId: "1:625136781456:web:cc2b266ce846e6e43af015",
  measurementId: "G-6DMJXVEEPP",
};

initializeApp(firebaseConfig);

createApp(App)
  .component("font-awesome-icon", FontAwesomeIcon)
  .use(store)
  .use(router)
  .mount("#app");
